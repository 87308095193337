import React, {Ref, useState} from "react";
import {CheckIcon} from "../components/CheckIcon";
import {QuestionIcon} from "../components/QuestionIcon";
import {BackIcon} from "../components/BackIcon";

interface ICheckOrderStatusScreenProps {
    onCheckStatus: (pickupNumber: string) => void 
}


export function CheckPickupStatusScreen({
                                           onCheckStatus
                                       }: ICheckOrderStatusScreenProps) {
    
    const [newPickupNumber, setPickupNumber] = useState("");
    const hasNoValue = !newPickupNumber || newPickupNumber.trim().length === 0;

    const onFormSubmit = (e: React.FormEvent<HTMLElement> | null) => {
        e?.preventDefault();
        if(hasNoValue){
            return;
        }
        onCheckStatus(newPickupNumber);
    }

    return (
        <div className={"px-8"}>
            <h1 className="text-2xl font-bold text-center text-tropicana-green">Check Pickup Status</h1>
            <p className="text-md text-center mt-1 italic">Save time by checking your order status online</p>
            <div className="mx-auto mt-8">
                <form onSubmit={onFormSubmit}>
                   
                
                    <label className="block mt-7">
                        <span className="text-xs font-semibold mb-2 block text-gray-500 tracking-wide uppercase">Enter pickup # <span className="text-red-700 font-bold">*</span></span>
                        <input type="text"
                               value={newPickupNumber}
                               onChange={e => setPickupNumber(e.target.value)}
                               className="rounded-md border border-gray-400 text-lg px-4 py-2 tracking-widest block w-full"
                               placeholder="00000000"/>
                    </label>

                </form>
                    
                <button disabled={hasNoValue}
                        className={"block uppercase rounded-md text-center w-full mt-8 py-3 font-semibold flex items-center justify-center " + (hasNoValue ? "bg-gray-400 opacity-30" : "bg-tropicana-green hover:bg-tropicana-green text-white")}
                        onClick={(e) => onFormSubmit(null)}>
                    <CheckIcon/> Check Status
                </button>
            </div>
        </div>
    );
}