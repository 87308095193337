export const locations = [
    {
        name: "Bradenton",
    },
    {
        name: "City of Industry",
    },
    {
        name: "Ft. Pierce",
    },
    {
        name: "Jersey City",
    }
];