import {RefreshIcon} from "../components/RefreshIcon";
import React, {useEffect, useState} from "react";
import {BackIcon} from "../components/BackIcon";
import {usePickupNumber} from "../api";


const cannotFindStatus = {
    statusCode: 0,
    status: "Cannot find pickup number"
};

export function ViewOrderScreen({
                                    onViewOrderBack,
                                    pickupNumber
                                }: { pickupNumber: string | null, onViewOrderBack: () => void }) {
    const {data, isError, isFetching, isLoading, refetch} = usePickupNumber(pickupNumber);
    
    const [result, setResult] = useState<any>(cannotFindStatus);
    
    useEffect(() => {
        if(!data){
            setResult(cannotFindStatus);
            return;
        }
        
        if(data.length === 1){
            setResult(data[0]);
        }
        else if(data.length > 1) {
            //Don't make them re-pick location on re-check status button
            const location = (result && typeof(result) === "object") ? result.location : null;
            const dataMatch = data.find((d: any) => d.location === location) || data;
            setResult(dataMatch);
        }
        else {
            setResult(cannotFindStatus);
        }
    }, [isFetching, data])

    
    if(result.length > 0){
        return (
            <div className="flex flex-col lg:flex-row lg:flex-wrap mx-10">
                {result.map((x: any) =>
                    <div
                        className={["cursor-pointer bg-gray-100 px-3 py-3 rounded shadow block  flex-grow text-center flex items-center justify-center hover:bg-gray-300 font-semibold w-full xl:w-1/2 border"].join(" ")}
                        onClick={() => setResult(x)}>{x.location}</div>
                )}
            </div>
        )
    }
    

    return (
        <div className="px-8">
            <div className="relative">
                <div className="mt-3 lg:flex flex-grow mt-6">
                    <div className="lg:w-1/2">
                        <div className="text-xs font-semibold text-gray-600">PICKUP #</div>
                        <div className="text-lg font-bold tracking-widest ">{result?.pickupNumber || pickupNumber}</div>
                    </div>
                    <div className="lg:w-1/2 lg:pl-1 mt-6 lg:mt-0">
                        <div className="text-xs font-semibold text-gray-600">LOCATION</div>
                        <div className="text-lg font-bold ">{result?.location}</div>
                    </div>
                </div>
                {
                    isError ?
                        <div className="text-lg py-4 text-center font-bold text-red-600">There was an error, please try
                            again.</div>
                        :
                        <React.Fragment>
                            {result?.statusCode > 0 &&
                            <div className="flex flex-grow mt-6">
                                <StatusStep statusCode={result?.statusCode} step={1}/>
                                <StatusStep statusCode={result?.statusCode} step={2}/>
                                <StatusStep statusCode={result?.statusCode} step={3}/>
                            </div>
                            }
                            <div className="mt-6 lg:flex">
                                <div className={["lg:w-1/2", result?.statusCode === 0 && "w-full lg:w-full"].join(" ")}>
                                    <div className="text-xs font-semibold text-gray-600">STATUS</div>
                                    <div className={"text-lg font-bold pr-2 "}>
                                        
                                        {isFetching ?
                                            <div className="text-lg font-bold text-"><RefreshIcon
                                                isRefreshing={isFetching}/> Loading...</div>
                                            : <div className={getColorFromStatus(result?.statusCode)}>{result?.status}</div>
                                        }
                                    </div>
                                </div>
                                {result?.statusCode > 0 && <div className="lg:w-1/2 lg:pl-1 mt-6 lg:mt-0">
                                    <div className="text-xs font-semibold text-gray-600">APPOINTMENT</div>
                                    <div className="text-lg font-bold">

                                        {result?.appointmentDate || "---"}

                                    </div>
                                </div>
                                }
                            </div>
                        </React.Fragment>
                }

            </div>
            <div className="mx-auto mt-8 flex lg:space-x-2 flex-col lg:flex-row flex-col-reverse lg:flex-row">
                <button
                    className="block uppercase bg-gray-300 rounded-md text-center w-full mt-3 py-3 font-semibold hover:bg-gray-400 flex items-center justify-center"
                    onClick={onViewOrderBack}><BackIcon/> Back
                </button>
                <button
                    className={"block uppercase bg-tropicana-green text-white rounded-md w-full mt-3 py-3 font-semibold hover:tropicana-orange flex items-center justify-center "}
                    onClick={() => refetch()}>

                    {isFetching
                        ? <span><RefreshIcon isRefreshing={isFetching}/> Loading...</span>
                        : <span><RefreshIcon isRefreshing={isFetching}/> Check Again</span>}
                </button>

            </div>
        </div>
    );
}


function getColorFromStatus(statusCode: number){
    if(statusCode === 0){
        return "text-red-600"
    }
    if(statusCode === 3){
        return "text-green-600"
    }

    return "text-gray-600";
}

function StatusStep({ statusCode, step } : { statusCode: number, step: number }){
    var bgColor;

    if(statusCode === 3){
        bgColor = "bg-green-500"
    }
    else if((statusCode === 1 && step == 1)
        || (statusCode === 2 && (step == 1 || step == 2))){

        bgColor = "bg-gray-500"
    }
    else {
        bgColor = "bg-gray-200"
    }
    return <div className={`h-2 w-1/3 mr-2 ${bgColor}`}/>;
}