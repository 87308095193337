import React, {Ref, useState} from "react";
import {CheckIcon} from "../components/CheckIcon";
import { locations } from "../locations";
import {QuestionIcon} from "../components/QuestionIcon";
import {BackIcon} from "../components/BackIcon";

export function  FaqScreen({
                                     onBack
                                 }: { onBack: () => void }) {
    
    const [locationName, setLocationName] = useState<string>();
    
    const location =locations.find(l => l.name === locationName);
    
    return (
        <div className=" leading-7">
            <div className="pl-12 text-2xl font-semibold text-tropicana-green">Frequently Asked Questions{location && (" - " + location.name)}</div>

            {!location && <>
                <div className="mt-8 ml-12">Choose a location...</div>
                <div className="grid  gap-4 grid-cols-2 mx-12 mt-4 mb-12">
                {locations.map(l =>
                    <button
                        onClick={() => setLocationName(l.name)}
                    className={"block uppercase text-center py-3 rounded-md  w-full font-semibold flex items-center justify-center bg-tropicana-green hover:bg-tropicana-green text-white"}
                    >
                        <QuestionIcon/> {l.name}
                    </button>
                )}
    
                </div>
            </>
            }

            {location && <div className="mt-8">
                <QuestionAnswer question={"Where can I find my pick-up number?"}
                                answer={"This number should be on your invoice. Look for an 8 or 10 digit number. If you do not see it, contact your dispatch."}/>
                <QuestionAnswer question={"My pick-up number is not working in the app.  Who do I contact?"}
                                answer={"Contact your dispatch to confirm your pick-up number."}/>
                <QuestionAnswer question={"What temperature should the reefer unit be set?"}
                                answer={"The temperature on the unit needs to be set at 32 degrees cycle."}/>
                {location.name === "Jersey City" && <QuestionAnswer question={"Where do I get by bills from?"}
                                                                    answer={"Your bills will be printed out at Security (same post where you checked in)."}/>
                }

                {(location.name === "Bradenton") && <div>
                    <QuestionAnswer question={"Finished good lot directions"}
                                    answer={<div >
                                        <div className={"font-semibold text-gray-600"}>The door numbers are posted above each door.</div>
                                        Restrooms are located past the 1st set of tracks on the left when entering.
                                        <ul className={"mt-3 list-disc pl-5"}>
                                            <li>
                                                <span className="font-semibold">Live Room</span> - Drive over 1st set of train tracks. Park in the 3rd row on the left. <span className="bg-yellow-100">A yard driver will come notify you and direct you to a door to be loaded when ready.</span>
                                            </li>
                                            <li>
                                                <span className="font-semibold">Loaded Lot</span> - Drive over the 1st set of train tracks. The trailers on both the left and right are all part of the loaded lot.
                                            </li>
                                             <li>
                                                <span className="font-semibold">Doors 5-26</span> - Drive over the 1st set of train tracks. Turn left after the 3rd row of trailers.
                                             </li>
                                             <li>
                                                <span className="font-semibold">Doors 27-32</span> - Drive over the 3rd set or train tracks and the doors are located on the left.
                                             </li>
                                             <li>
                                                <span className="font-semibold">Doors 33-39</span> - Drive over the 3rd set of train track. The doors are located on the right.
                                             </li>
                                             <li>
                                                <span className="font-semibold">Lot 21</span> - Drive over the 2nd set of train tracks. At the stop sign, turn right. It is the 2nd lot on the left. (Look for the sign).
                                             </li>
                                             <li>
                                                <span className="font-semibold">Backhaul-Rail dock</span> - Turn at side of road behind post7. Drive to the end of the warehouse. The dock is on the west side of the building.
                                             </li>

                                        </ul>
                                    </div>}/>
                </div>}

                {(location.name === "Jersey City") && <div>
                    <QuestionAnswer question={"Truck Yard Directions"}
                                    answer={<div >
                                        <div className={"font-semibold text-gray-600"}>The door numbers are posted above each door.</div>
                                        Restrooms are in the main building outside of the Dispatch Office.
                                        <ul className={"mt-3 list-disc pl-5"}>
                                            <li>
                                                <span className="font-semibold">Live Row</span> - Is located across dock doors 1 - 23.<br/><span className="bg-yellow-100">A yard jockey will come notify you and direct you to a door to be loaded when ready.</span>
                                            </li>
                                            <li>
                                                <span className="font-semibold">Reefer Settings</span> – Set your reefer at 32 degrees.
                                            </li>
                                            <li>
                                                <span className="font-semibold">Trailer Doors</span> – Keep trailer doors closed
                                            </li>
                                            <li>
                                                <span className="font-semibold">Loaded Trailers</span> – Upon entering the yard – the loaded trailers are located to the left in front of the lawn.
                                            </li>
                                            <li>
                                                <span className="font-semibold">Empty Trailers</span> – Upon entering the yard – the empty trailers are located across from the loaded trailers
                                            </li>
                                            <li>
                                                <span className="font-semibold">Doors 24 - 33</span> – Canadian & Domestic Costco loading area
                                            </li>
                                            <li>
                                                <span className="font-semibold">Doors 1 - 14</span> – Domestic loading area
                                            </li>
                                            <li>
                                                <span className="font-semibold">Doors 15 – 18</span> – Inbound trucks unloading area
                                            </li>
                                            <li>
                                                <span className="font-semibold">Backhaul-Crossdock</span> – Doors 19 – 21
                                            </li>
                                            <li>
                                                <span className="font-semibold">Kevita Inbound Trucks</span> – Doors R1, R2 and R3 – West end of the building
                                            </li>
                                        </ul>
                                    </div>}/>
                </div>}

                <div className="pl-12 mt-12 text-2xl font-semibold  text-tropicana-green">Plant Rules{location && (" - " + location.name)}</div>

                {(location.name === "Bradenton" || location.name === "Ft. Pierce" || location.name === "City of Industry") &&
                    <div className="mx-12 mb-6">
                        <div className="font-semibold text-xl mt-4">General Plant Rules</div>
                        <ul className={"mt-4 list-disc pl-5"}>
                            <li>Always yield to forklift and pedestrian traffic. Stop at all traffic signs.</li>
                            <li>Follow all warnings and information signs located in the area of the plant you are
                                visiting.
                            </li>
                            <li>Any driver exiting their truck must have Close toed shoes, safety glasses, and high
                                visibility clothing.
                            </li>
                            <li>No smoking, drinking, and/or eating except in designated areas in the plant.</li>
                            <li>Do not throw trash or cigarette butts on the floor - out of the vehicle.</li>
                            <li>Do not enter any part of a department.</li>
                            <li>No animals permitted on the grounds (they need to remain in the vehicle).</li>
                            <li>The use of MP3 or other music device headphones or ear-buds is prohibited in all areas
                                except in offices, administrative areas, or designated break areas.
                            </li>
                            <li>Cell phone or cell phone-related device use (e.g, ear-buds, Bluetooth devices, etc.) may
                                not be in place while operating any vehicle, golf cart, or maintenance cart or within
                                production, warehousing, utilities and/or maintenance areas.
                            </li>
                            <li>Plant speed is 15 mph.</li>
                            <li>Always stop and look at all rail crossings. Do not cross railroad tracks while lights
                                and bells are on and no stopping on railroad tracks.
                            </li>
                            <li>No sleeping on property.</li>
                        </ul>

                        <div className="font-semibold text-xl mt-6">Emergency Evacuations</div>
                        <ul className={"mt-4 list-disc pl-5"}>
                            <li>The Facility is monitored with a state-of the-art ammonia and fire alarm system. In the
                                event that this system detects a hazard, the evacuation alarm will sound.
                            </li>
                            <li>When the alarm sounds, contact the nearest security post for any direction to assembly
                                areas.
                            </li>
                            <li>Upon reaching the assembly area, please acknowledge your presence during the roll call.
                                Do not leave the assembly area until further instructions are provided.
                            </li>
                        </ul>

                        <div className="text-red-600 font-semibold mt-4">
                            Drivers will be asked to leave the facility if violating Tropicana Policies.
                        </div>
                        <div className="font-semibold mt-4">
                            SAFETY RULES ARE FOR YOUR PROTECTION AND ARE NOT AN OPTION!
                        </div>
                    </div>
                } 
                {(location.name === "Jersey City") &&
                    <div className="mx-12 mb-6">
                        <div className="font-semibold text-xl mt-4">General Plant Rules</div>
                        <ul className={"mt-4 list-disc pl-5"}>
                            <li>Always yield to forklift and pedestrian traffic. Stop at all traffic signs.</li>
                            <li>Follow all warnings and information signs located in the area of the plant you are visiting.</li>
                            <li>Any driver exiting their truck must have Close toed shoes, safety glasses, and high visibility clothing.</li>
                            <li className={"text-red-600"}>Drivers are only permitted to enter the Security office to check in, check out and restrooms. Drivers are not permitted to enter any other building.</li>
                            <li>No smoking, drinking, and/or eating except in designated areas in the plant.</li>
                            <li>Do not throw trash or cigarette butts on the floor - out of the vehicle.</li>
                            <li>If loading/unloading live, always stay with your vehicle on the live line.  A Yard Jockey will let you know what door to go to.</li>
                            <li>Do not enter any part of a department.</li>
                            <li>No animals permitted on the grounds (they need to remain in the vehicle).</li>
                            <li>The use of MP3 or other music device headphones or ear-buds is prohibited in all areas except in offices, administrative areas, or designated break areas.</li>
                            <li>Cell phone or cell phone-related device use (e.g, ear-buds, Bluetooth devices, etc.) may not be in  place while operating any vehicle, golf cart, or maintenance cart or within production, warehousing, utilities and/or maintenance areas.</li>
                            <li>Plant speed is 15 mph.</li>
                            <li>Always stop and look at all rail crossings. Do not cross railroad tracks while lights and bells are on and no stopping on railroad tracks.</li>
                            <li>No sleeping on property.</li>

                        </ul>

                        <div className="font-semibold text-xl mt-6">Emergency Evacuations</div>
                        <ul className={"mt-4 list-disc pl-5"}>
                            <li>The Facility is monitored with a state-of the-art ammonia and fire alarm system. In the event that this system detects a hazard, the evacuation alarm will sound.</li>
                            <li>When the alarm sounds, contact the nearest security post for any direction to assembly areas.</li>
                            <li>Upon reaching the assembly area, please acknowledge your presence during the roll call. Do not leave the assembly area until further instructions are provided.</li>

                        </ul>

                        <div className="text-red-600 font-semibold mt-4">
                            Drivers will be asked to leave the facility if violating Tropicana Policies.
                        </div>
                        <div className="font-semibold mt-4">
                            Report all emergencies from any plant phone by dialing extension 4-6004 (Security) from any plant phone, or direct 201-395-6004.
                        </div>
                        <div className="font-semibold mt-4">
                            SAFETY RULES ARE FOR YOUR PROTECTION AND ARE NOT AN OPTION!
                        </div>
                    </div>
                }
            </div>}

            <div className="flex items-center justify-center">
            <button
                className="block uppercase bg-gray-300 rounded-md w-1/2 text-center mt-3 py-3 mx-8 font-semibold hover:bg-gray-400 flex items-center justify-center"
                onClick={onBack}><BackIcon/> Back
            </button>
            </div>
            
        </div>
    );
}

function QuestionAnswer({ question, answer} : { question: string, answer: React.ReactFragment}){
    return (
        <div className="mx-12 mb-6">
            <div className="font-semibold"><QuestionIcon/> {question}</div>
            <div>{answer}</div>
        </div>
    )
}