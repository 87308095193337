import {useQuery} from "react-query";
import {minimumDuration} from "./util";


export function usePickupNumber(pickupNumber : string|null) {
    return useQuery(["pickupNumber", pickupNumber], minimumDuration(200, async () => {
        const url = "/api/PickupStatus/GetStatus";
        // @ts-ignore
        let params = new URLSearchParams({pickupNumber: pickupNumber}).toString();
        const res = await fetch(url + "?" + params);
        return res.json();
    }), { enabled: pickupNumber != null, refetchOnWindowFocus: false });
}


export function useLastUpdatedDate() {
    return useQuery(["lastupdateddate"], minimumDuration(0, async () => {
        const url = "/api/PickupStatus/GetLastUpdatedDate";
        const res = await fetch(url);
        return res.json();
    }), { refetchInterval: 30 * 1000 });
}

