export function minimumDuration<T>(duration: number, innerPromise: () => any){
    return async function(){
        try {
            const start = new Date();

            const result = await innerPromise();

            const end = new Date();
            const elapsed = end.getTime() - start.getTime();

            await delay<T>(Math.max(0, duration - elapsed))

            return result;
        }
        catch(err) {
            throw err;
        }
    }
}

function delay<T>(duration: number) : Promise<T> {
    return new Promise<T>(function(resolve, reject){
        setTimeout(function(){
            // @ts-ignore
            resolve.apply(this, arguments);
        }, duration)
    });
}