import React, {useEffect, useRef, useState} from "react";
import {ViewOrderScreen} from "./screens/ViewOrderScreen";
import {useLastUpdatedDate, usePickupNumber} from "./api";
import {CheckPickupStatusScreen} from "./screens/CheckOrderStatusScreen";
import {QuestionIcon} from "./components/QuestionIcon";
import {FaqScreen} from "./screens/FaqScreen";


const SCREEN = {
	CHECKSTATUS: "CHECKSTATUS",
	VIEWORDER: "VIEWORDER",
	FAQ: "FAQ",
}

function Container() {
	const [screen, setScreen] = useState(SCREEN.CHECKSTATUS);
	const [pickupNumber, setPickupNumber] = useState<string|null>(null);
	const [location, setLocation] = useState<string|null>(null);
	const { refetch } = usePickupNumber(pickupNumber);
	const lastUpdatedDate = useLastUpdatedDate();

	function onCheckStatus(newPickupNumber: string){
		if(pickupNumber != null){
			refetch();
		}
		setPickupNumber(newPickupNumber);
		setLocation(location);
		setScreen(SCREEN.VIEWORDER);
	}
	
	function onViewOrderBack() {
		setScreen(SCREEN.CHECKSTATUS)
	}
	
	function onFAQ() {
		setScreen(SCREEN.FAQ)
	}

	return (
		<div className="bg-white md:w-2/3 lg:w-6/12 xl:w-5/12 2xl:w-1/3 w-full md:mt-8 mx-auto rounded-xl pt-4 pb-6 lg:shadow-md">
			<img className="block mx-auto h-10" src={"logo.png" }  alt="Logo"/>
			<div className="border-t h-1 mt-6 mb-4"/>
			{screen === SCREEN.CHECKSTATUS && <CheckPickupStatusScreen onCheckStatus={onCheckStatus}/>}
			{screen === SCREEN.VIEWORDER && <ViewOrderScreen pickupNumber={pickupNumber} onViewOrderBack={onViewOrderBack}/>}
			{screen === SCREEN.FAQ && <FaqScreen onBack={onViewOrderBack}/>}



			{screen != SCREEN.FAQ && <a
				onClick={onFAQ}
				className={"text-center mt-8 py-3 flex items-center justify-center underline cursor-pointer"}>
				<QuestionIcon/>Frequently Asked Questions
			</a>}
			{lastUpdatedDate.data ? <div className="text-center text-xs mt-4 text-gray-600">Statuses last updated {lastUpdatedDate.data.date}</div> : <div/>}
		</div>
	);
}


export default Container;